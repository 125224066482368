import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/lutein-sinia-svetlina-header.jpg"
import LuteinComplexTablet from "./../../images/lutein-sinia-svetlina-header.jpg"
import LuteinComplexDesktop from "./../../images/lutein-sinia-svetlina-header.jpg"
import LuteinComplexXL from "./../../images/lutein-sinia-svetlina-header.jpg"

import Display from "./../../images/lutein-sinia-svetlina-blue-screen-display.jpg"

const LuteinSiniaSvetlina = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-sinia-svetlina" }}>
    <Seo title="Ефекта на синята светлина върху очите" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ЕФЕКТА НА СИНЯТА СВЕТЛИНА ВЪРХУ ОЧИТЕ
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Ефекта на синята светлина върху очите"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Ефекта на синята светлина върху очите
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={8}>
          <p>
            Всички знаем за вредите на ултравиолетовите лъчи от слънцето върху
            кожата ни. Но освен кожата, очите ни също са изложени на постоянна
            вреда от слънцето и щетите могат да бъдат много сериозни.
          </p>
          <p>
            За разлика от други части на човешкото тяло, лещата на окото не
            регенерира, когато е наранена. Наранените клетки с течение на
            времето могат да доведат до сериозни очни заболявания и дори загуба
            на зрението.
          </p>
          <p>
            Както UV, така и сините лъчи могат да нанесат щети на очите.
            Вредните ефекти от тях са 3 пъти повече през лятото в сравнение със
            зимните месеци.
          </p>
        </Col>
        <Col sm={4}>
          <Image className="w-100 float-right" src={Display} />
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Излагането на окото на въздействията на вредната синя и УВ светлина,
            без да са взети мерки може да доведе до:
          </p>
          <ul>
            <li>Рак на кожата около очите</li>
            <li>Фотокератит - слънчево изгаряне на роговицата</li>
            <li>Увреждане на чувствителните фоторецептори</li>
            <li>Фотофобия</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col>
          <p>
            Синята светлина не идва само от слънцето, но и от други източници на
            светлина - флуоресцентни лампи,всички видове екрани и пр. За разлика
            от UV лъчите, синята светлина е видима за окото. Тя прави небето и
            морето синьо. Тя е най-силната част от спектъра на видимата светлина
            с дължина на вълната 400-500 nm. Притежава силата да проникне през
            тъканта и да причини щети на клетките. Има научни доказателства за
            вредите на синята светлина върху клетките на ретината или по-точно
            върху техните източници на енергия – митохондриите. Действието на
            синята светлина образува свободни радикали, които са изключително
            вредни, могат да доведат до още по-сериозни увреждания на клетките и
            до болести.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>
            Можете да откриете ЛУТЕИН КОМПЛЕКС ПЛЮС в най-близката до вас
            аптека.
          </p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/lutein-complex">
              Повече информация за ЛУТЕИН КОМПЛЕКС ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LuteinSiniaSvetlina
